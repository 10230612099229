<template>
  <section id="hero">
    <base-jumbotron :src="require('@/assets/about-bg.jpg')">
      <v-container fill-height>
        <v-layout
          align-center
          justify-center
        >
          <base-title>
            About Us
          </base-title>
        </v-layout>
      </v-container>
    </base-jumbotron>
  </section>
</template>

<script>
  export default {
    name: 'Hero'
  }
</script>

<style scoped>

</style>
